import React from "react";

export default class Header extends React.Component {
    render() {
        return (
            <nav style={this.props.style} className="navbar navbar-expand-lg fixed-top navbar-light bg-light">
                <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <a className="navbar-brand" href="/#"
                   name={"brand"}
                   onClick={this.props.onClick}>Djamma Dev</a>

                <div className="collapse navbar-collapse" id="navbarTogglerDemo03">
                    <ul className="navbar-nav mr-auto mt-2 mt-lg-0">
                        {/*<li className="nav-item active">
                            <a className="nav-link" href="/#"
                               name={"play"}
                               onClick={this.props.onClick}>Jouer seul</a>
                        </li>*/}
                    </ul>
                    <ul className={"navbar-nav"}>
                        <li className="nav-item">
                            <a className={"nav-link"}
                               href="#/client">Version simplifiée</a>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}