import React from "react";

export default class Client extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalVersement: 32634.09,
            chargePatronale: 8553.52,
            salaire: 4417,
            nombreJour: 17,
            tjm: 830,
            tauxPartner: 0.95
        };
        this.handleChanged = this.handleChanged.bind(this);
        window.document.title = this.props.title
    }

    percent(value) {
        return value > 1 ? value / 100.0 : value;
    }

    brut() {
        return this.state.totalVersement - this.state.chargePatronale;
    }

    profit() {
        return this.state.tjm * this.state.nombreJour;
    }

    charge() {
        return this.state.salaire + this.state.chargePatronale;
    }

    marge() {
        return this.profit() - this.charge();
    }

    margeEntreprise() {
        return this.marge() - this.margePartner();
    }

    margePartner() {
        return this.marge() * this.percent(this.state.tauxPartner);
    }

    totalDuSalarie() {
        return this.state.salaire + this.margePartner();
    }

    totalRestantSalarie() {
        return this.totalDuSalarie() - this.brut();
    }

    handleChanged(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value * 1
        })
    }

    render() {
        return (
            <>
                <h1>{this.props.title}</h1>
                <form>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor="salaire">Part fixe (salaire)</label>
                            <input type="number" name="salaire" value={this.state.salaire} onChange={this.handleChanged}
                                   min="0" className="form-control" id="salaire" aria-describedby="salaireHelp"
                                   placeholder="Salaire"/>
                            <small id="salaireHelp" className="form-text text-muted">Part fixe (salaire).</small>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="nombreJour">Jour facturé</label>
                            <input type="number" name="nombreJour" value={this.state.nombreJour}
                                   onChange={this.handleChanged}
                                   min="0" step="1" className="form-control" id="nombreJour"
                                   aria-describedby="nombreJourHelp" placeholder="Jour facturé"/>
                            <small id="nombreJourHelp" className="form-text text-muted">Nombre de jour
                                travaillé.</small>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="tjm">TJM</label>
                            <input type="number" name="tjm" value={this.state.tjm} onChange={this.handleChanged}
                                   min="0" step="1" className="form-control" id="tjm" aria-describedby="tjmHelp"
                                   placeholder="TJM"/>
                            <small id="tjmHelp" className="form-text text-muted">TJM.</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="cout">Charge patroname</label>
                            <input type="number" name="chargePatronale" value={this.state.chargePatronale} onChange={this.handleChanged}
                                   className="form-control" style={{color: 'red'}} id="cout" aria-describedby="coutHelp"
                                   placeholder="Charge patronale"/>
                            <small id="countHelp" className="form-text text-muted">Charge liée au versement.</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="allegement">Coût total versé par l'employeur</label>
                            <input type="number" name="totalVersement" value={this.state.totalVersement}
                                   onChange={this.handleChanged}
                                   className="form-control" id="allegement" aria-describedby="allegementHelp"
                                   placeholder="Versement total"/>
                            <small id="allegementHelp" className="form-text text-muted"> Versement total.</small>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="form-group col-md-12">
                            <label htmlFor="tauxPartner">Taux partenaire</label>
                            <input type="number" name="tauxPartner" value={this.state.tauxPartner}
                                   onChange={this.handleChanged}
                                   min="0" step="0.01" max="100" className="form-control" id="tauxPartner"
                                   aria-describedby="tauxPartnerHelp" placeholder="Taux Partenaire"/>
                            <small id="tauxPartnerBHelp" className="form-text text-muted">Taux partenaire
                                (indep.)</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label>Marge globale</label>
                            <input value={this.marge()} className="form-control" readOnly={true}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Marge entreprise</label>
                            <input value={this.margeEntreprise()} className="form-control" style={{color: 'darkgreen'}}
                                   readOnly={true}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Charge globale</label>
                            <input value={this.charge()} className="form-control" style={{color: 'red'}}
                                   readOnly={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label>Chiffre d'affaire</label>
                            <input value={this.profit()} className="form-control" style={{color: 'darkgreen'}}
                                   readOnly={true}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Brut</label>
                            <input value={this.brut()} className="form-control" readOnly={true}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Marge Salarié</label>
                            <input value={this.margePartner()} className="form-control" readOnly={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label>Total Du Salarié</label>
                            <input value={this.totalDuSalarie()} className="form-control" style={{color: 'darkgreen'}}
                                   readOnly={true}/>
                        </div>
                        <div className="form-group col-md-6">
                            <label>Total restant salarié</label>
                            <input value={this.totalRestantSalarie()} className="form-control"
                                   style={{color: this.totalRestantSalarie() > 0 ? 'red' : 'darkgreen'}}
                                   readOnly={true}/>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}