import React from 'react';
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import './App.css';
import Company from "./Company";
import Client from "./Client";
import Header from "./Header";

export default class App extends React.Component {

    render() {
        return (
            <Router>
                <div className="container">
                    <Header/>
                    <Switch>
                        <Route exact path="/">
                            <Company title={"Calcul variable pour indep."}/>
                        </Route>
                        <Route exact path="/client">
                            <Client title={"Calcul variable pour indep. simplifié"}/>
                        </Route>
                    </Switch>
                </div>
            </Router>
        );
    }
}
