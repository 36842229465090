import React from "react";

export default class Company extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fixedCost: 37.5 + 75.2,
            ticket: 4.5,
            salaire: 3428,
            prime: 0,
            nombreJour: 20,
            tjm: 500,
            cout: 0,
            allegement: 22.76,
            trancheA: 3428,
            smic: 1539.42,
            plafondAllegement: 32.05,
            tauxBrut: 27,
            tauxTrancheA: 15.77,
            tauxTrancheB: 15.35,
            tauxPartner: 95,
            tauxUrssaf: 24.4,
        };
        this.handleChanged = this.handleChanged.bind(this);
        window.document.title = this.props.title
    }

    percent(value) {
        return value > 1 ? value / 100.0 : value;
    }

    profit() {
        return this.state.tjm * this.state.nombreJour;
    }

    brut() {
        return this.state.salaire + this.state.prime;
    }

    trancheA() {
        return this.brut() < this.state.trancheA ? 0 : this.state.trancheA;
    }

    trancheB() {
        return this.brut() < this.state.trancheA ? 0 : Math.max(0, this.brut() - this.trancheA());
    }

    cout() {
        return this.state.cout + this.state.fixedCost + this.state.nombreJour * this.state.ticket;
    }

    coefAllegement() {
        if (this.brut() < this.state.smic) {
            return this.state.plafondAllegement / 100
        }
        let coef = this.state.smic / this.brut();
        coef = coef * 1.6;
        coef = coef - 1;
        coef = coef * (this.state.plafondAllegement / 0.6);
        return Math.max(0, Math.min(this.state.plafondAllegement, coef)) / 100;
    }

    allegement() {
        //return this.state.salaire > 0 ? this.state.allegement : 0;
        return this.allegementCalcule();
    }

    allegementCalcule() {
        return Math.max(this.state.allegement, this.brut() * this.coefAllegement());
    }

    charge() {
        return this.state.salaire + (this.brut() * this.percent(this.state.tauxBrut))
            + (this.trancheA() * this.percent(this.state.tauxTrancheA))
            + (this.trancheB() * this.percent(this.state.tauxTrancheB))
            + this.cout() - this.allegement();
    }

    marge() {
        return this.profit() - this.charge();
    }

    margeEntreprise() {
        return this.marge() - this.margePartner();
    }

    margePartner() {
        return this.marge() * this.percent(this.state.tauxPartner);
    }

    totalDuSalarie() {
        return this.state.salaire + this.margePartner();
    }

    totalRestantSalarie() {
        return this.totalDuSalarie() - this.brut();
    }

    totalSalarie() {
        return this.brut() * 1 + this.totalRestantSalarie() * (1 - this.percent(this.state.tauxUrssaf));
    }

    coutTotal() {
        return this.state.fixedCost + (this.state.nombreJour * this.state.ticket);
    }

    targetPrime() {
        let P = this.profit();
        let tp = this.percent(this.state.tauxPartner);
        let tb = this.percent(this.state.tauxBrut);
        let tTB = this.percent(this.state.tauxTrancheB);
        let tTA = this.percent(this.state.tauxTrancheA);
        let TA = this.trancheA();
        let S = this.state.salaire;
        return (tp / (1 + tp * (tb + tTB))) * ((P - TA * (tTA - tTB) - (1 + tb + tTB) * S) - (this.cout() - this.allegement()));
    }

    handleChanged(event) {
        let name = event.target.name;
        let value = event.target.value;
        this.setState({
            [name]: value * 1
        })
    }

    render() {
        return (
            <>
                <h1>{this.props.title}</h1>
                <form>
                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="salaire">Part fixe (salaire)</label>
                            <input type="number" name="salaire" value={this.state.salaire} onChange={this.handleChanged}
                                   min="0" className="form-control" id="salaire" aria-describedby="salaireHelp"
                                   placeholder="Salaire"/>
                            <small id="salaireHelp" className="form-text text-muted">Part fixe (salaire).</small>
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="prime">Prime</label>
                            <input type="number" name="prime" value={this.state.prime} onChange={this.handleChanged}
                                   style={{color: this.totalRestantSalarie() > 0 ? 'red' : 'darkgreen'}}
                                   min="0" className="form-control" id="prime" aria-describedby="primeHelp"
                                   placeholder="Prime"/>
                            <small id="primeHelp" className="form-text text-muted">Prime d'équilibre :
                                ({this.targetPrime()}).</small>
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="nombreJour">Jour facturé</label>
                            <input type="number" name="nombreJour" value={this.state.nombreJour}
                                   onChange={this.handleChanged}
                                   min="0" step="1" className="form-control" id="nombreJour"
                                   aria-describedby="nombreJourHelp" placeholder="Jour facturé"/>
                            <small id="nombreJourHelp" className="form-text text-muted">Nombre de jour
                                travaillé.</small>
                        </div>
                        <div className="form-group col-md-3">
                            <label htmlFor="tjm">TJM</label>
                            <input type="number" name="tjm" value={this.state.tjm} onChange={this.handleChanged}
                                   min="0" step="1" className="form-control" id="tjm" aria-describedby="tjmHelp"
                                   placeholder="TJM"/>
                            <small id="tjmHelp" className="form-text text-muted">TJM.</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="cout">Coût total</label>
                            <input type="number" name="cout" value={this.state.cout} onChange={this.handleChanged}
                                   className="form-control" style={{color: 'red'}} id="cout" aria-describedby="coutHelp"
                                   placeholder="Count total"/>
                            <small id="countHelp" className="form-text text-muted">Cout total dépensé par
                                l'employeur <b>{this.cout()}</b>.</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="allegement">Allègement</label>
                            <input type="number" name="allegement" value={this.state.allegement}
                                   onChange={this.handleChanged}
                                   className="form-control" id="allegement" aria-describedby="allegementHelp"
                                   placeholder="Allègement"/>
                            <small id="allegementHelp" className="form-text text-muted">Allègement
                                calculé {this.allegementCalcule()}.</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-6">
                            <label htmlFor="trancheA">Tranche A</label>
                            <input type="number" name="trancheA" value={this.state.trancheA}
                                   onChange={this.handleChanged}
                                   min="0" className="form-control" id="trancheA" aria-describedby="trancheAHelp"
                                   placeholder="Tranche A"/>
                            <small id="trancheAHelp" className="form-text text-muted">La première
                                tranche {this.trancheA()}.</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="trancheA">Tranche B</label>
                            <input type="number" name="trancheB" value={this.trancheB()} onChange={this.handleChanged}
                                   min="0" className="form-control" id="trancheB" aria-describedby="trancheBHelp"
                                   placeholder="Tranche B" readOnly={true}/>
                            <small id="trancheBHelp" className="form-text text-muted">La seconde tranche.</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label htmlFor="tauxBrut">Taux brut</label>
                            <input type="number" name="tauxBrut" value={this.state.tauxBrut}
                                   onChange={this.handleChanged}
                                   min="0" step="0.01" max="100" className="form-control" id="tauxBrut"
                                   aria-describedby="tauxBrutHelp" placeholder="Taux brut"/>
                            <small id="tauxBrutHelp" className="form-text text-muted">Taux sur le brut total (salaire +
                                prime).</small>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="tauxTrancheA">Taux tranche A</label>
                            <input type="number" name="tauxTrancheA" value={this.state.tauxTrancheA}
                                   onChange={this.handleChanged}
                                   min="0" step="0.01" max="100" className="form-control" id="tauxTrancheA"
                                   aria-describedby="tauxTrancheAeHelp" placeholder="Taux Tranche A"/>
                            <small id="tauxTrancheAHelp" className="form-text text-muted">Taux de la première
                                tranche.</small>
                        </div>
                        <div className="form-group col-md-4">
                            <label htmlFor="tauxTrancheB">Taux tranche B</label>
                            <input type="number" name="tauxTrancheB" value={this.state.tauxTrancheB}
                                   onChange={this.handleChanged}
                                   min="0" step="0.01" max="100" className="form-control" id="tauxTrancheB"
                                   aria-describedby="tauxTrancheBeHelp" placeholder="Taux Tranche B"/>
                            <small id="tauxTrancheBHelp" className="form-text text-muted">Seconde tranche.</small>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="form-group col-md-6">
                            <label htmlFor="tauxPartner">Taux partenaire</label>
                            <input type="number" name="tauxPartner" value={this.state.tauxPartner}
                                   onChange={this.handleChanged}
                                   min="0" step="0.01" max="100" className="form-control" id="tauxPartner"
                                   aria-describedby="tauxPartnerHelp" placeholder="Taux Partenaire"/>
                            <small id="tauxPartnerBHelp" className="form-text text-muted">Taux partenaire
                                (indep.)</small>
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="tauxUrssaf">Taux URSSAF</label>
                            <input type="number" name="tauxUrssaf" value={this.state.tauxUrssaf}
                                   onChange={this.handleChanged}
                                   min="0" step="0.01" max="100" className="form-control" id="tauxUrssaf"
                                   aria-describedby="tauxPartnerHelp" placeholder="Taux URSSAF"/>
                            <small id="tauxUrssafBHelp" className="form-text text-muted">Taux URSSAF.</small>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label>Marge globale</label>
                            <input value={this.marge()} className="form-control" readOnly={true}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Marge entreprise</label>
                            <input value={this.margeEntreprise()} className="form-control" style={{color: 'darkgreen'}}
                                   readOnly={true}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Charge globale</label>
                            <input value={this.charge()} className="form-control" style={{color: 'red'}}
                                   readOnly={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label>Chiffre d'affaire</label>
                            <input value={this.profit()} className="form-control" style={{color: 'darkgreen'}}
                                   readOnly={true}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Total salarié</label>
                            <input value={this.brut()} className="form-control" readOnly={true}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Marge Salarié</label>
                            <input value={this.margePartner()} className="form-control" readOnly={true}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label>Total Du Salarié</label>
                            <input value={this.totalDuSalarie()} className="form-control" style={{color: 'darkgreen'}}
                                   readOnly={true}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Total restant salarié</label>
                            <input value={this.totalRestantSalarie()} className="form-control"
                                   style={{color: this.totalRestantSalarie() > 0 ? 'red' : 'darkgreen'}}
                                   readOnly={true}/>
                        </div>
                        <div className="form-group col-md-4">
                            <label>Total salarié avec URSSAF</label>
                            <input value={this.totalSalarie()} className="form-control"
                                   readOnly={true}/>
                        </div>
                    </div>
                </form>
            </>
        );
    }
}